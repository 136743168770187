<template>
  <q-toolbar class="bg-gray-1 q-my-none">
    <div class="q-pa-none q-gutter-sm pro">
      <q-breadcrumbs active-color="black">
        <q-breadcrumbs-el
          v-for="(item, key) in breadcrumbs"
          :key="`b_${key}`"
          :label="getRes(item.name)"
          :icon="item.icon"
          :to="item.to"
          :class="item.to ? 'underline text-primary' : ''"
        />
      </q-breadcrumbs>
    </div>
    <q-space />
    <q-btn
      stretch
      flat
      no-caps
      :label="getRes('Document.Breadcrumbs.Edit')"
      v-if="show.edit"
      :to="{ name: 'DocumentEdit' }"
    ></q-btn>
    <q-separator dark vertical />
    <q-btn
      stretch
      flat
      :label="getRes('Document.Breadcrumbs.ApplicationForm')"
      no-caps
      v-if="show.applicationForm"
      :to="{ name: 'ApplicationForm' }"
    ></q-btn>
    <q-separator dark vertical />
    <q-btn-dropdown
      stretch
      flat
      icon="PROSmart-Setting"
      no-caps
      v-if="show.setting || show.tenderer"
    >
      <q-list>
        <q-item
          v-for="formItem in settingMenu[userRole]"
          :key="formItem.text"
          clickable
          v-close-popup
          tabindex="0"
          :to="formItem.to"
        >
          <q-item-section>
            <q-item-label>{{ getRes(formItem.text) }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </q-toolbar>
</template>

<script>
export default {
  name: "ProDocumentBreadcrumbs",
  props: ["breadcrumbs", "userRole", "tenderId", "editUserRouteId"],
  data() {
    return {
      editUserId: null,
      settingMenu: {
        ProSmartBuyer: [
          {
            text: "menu.ViewAuditTrails",
            to: { name: "DocumentViewAuditTrails" },
          },
          {
            text: "menu.ViewDownloadLogs",
            to: { name: "DocumentViewDownloadLogs" },
          },
        ],
        Tenderer: [
          {
            text: "menu.ViewTendererAuditTrails",
            to: { name: "DocumentViewTendererAuditTrails" },
          },
        ],
      },
      abortCode: "",
      abortText: "",
    };
  },
  computed: {
    show() {
      let showButton = {
        edit: false,
        view: false,
        applicationForm: false,
        setting: false,
        tenderer: false,
      };

      switch (this.userRole) {
        case "ProSmartBuyer":
          if (this.tenderId > 0) {
            showButton = {
              ...showButton,
              view: true,
              applicationForm: true,
              setting: true,
            };
          }
          break;
        case "Tenderer":
          if (this.tenderId > 0) {
            showButton = {
              ...showButton,
              tenderer: true,
            };
          }
          break;
        case "ProSmartBackupBuyer":
          if (this.tenderId > 0) {
            showButton = {
              ...showButton,
              applicationForm: true,
            };
          }
          break;
      }

      return showButton;
    },
  },
  methods: {
    addViewAssignedUser() {
      let index = this.settingMenu["ProSmartBuyer"].findIndex(
        (b) => b.text === "menu.ViewAssignedUser"
      );

      if (index === -1) {
        this.settingMenu["ProSmartBuyer"].push({
          text: "menu.ViewAssignedUser",
          to: {
            name: "DocumentViewAssignedUser",
            params: { stepperId: this.editUserId },
          },
        });
      } else {
        this.settingMenu["ProSmartBuyer"][index] = {
          text: "menu.ViewAssignedUser",
          to: {
            name: "DocumentViewAssignedUser",
            params: { stepperId: this.editUserId },
          },
        };
      }
    },
  },
  watch: {
    editUserRouteId(newValue) {
      this.editUserId = newValue;
      this.addViewAssignedUser();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  .flex {
    flex-direction: row;
  }
}
</style>
