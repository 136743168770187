import { render, staticRenderFns } from "./TodoList.vue?vue&type=template&id=ff2de10a&scoped=true&"
import script from "./TodoList.vue?vue&type=script&lang=js&"
export * from "./TodoList.vue?vue&type=script&lang=js&"
import style0 from "./TodoList.vue?vue&type=style&index=0&id=ff2de10a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff2de10a",
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QList from 'quasar/src/components/item/QList.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QTab,QBadge,QInput,QIcon,QTabPanels,QTabPanel,QCard,QList});
