<template>
  <div>
    <pro-report
      title="Document.SearchTodoList"
      :columns="columns"
      :value="todoItems"
      row-key="id"
      auto-search
      :selectable="clickable"
    />

    <!--    <pro-expansion-item-->
    <!--      v-for="todo in data"-->
    <!--      :key="todo.id"-->
    <!--      :to="getRoute(todo)"-->
    <!--      expand-icon-toggle-->
    <!--      :class="getClass(todo)"-->
    <!--      @click.native="saveTodoItem(todo)"-->
    <!--    >-->
    <!--      <template #header>-->
    <!--        <q-item-section>-->
    <!--          <q-item-label class="text-h4 prosmart-text-important"-->
    <!--            >{{ todo.details }}-->
    <!--          </q-item-label>-->

    <!--          <div class="row text-grey-6 q-pt-sm">-->
    <!--            <div class="col-auto">-->
    <!--              <q-markup-table-->
    <!--                dense-->
    <!--                flat-->
    <!--                separator="none"-->
    <!--                style="background-color: transparent"-->
    <!--              >-->
    <!--                <thead></thead>-->
    <!--                <tbody>-->
    <!--                  <tr class="q-tr--no-hover">-->
    <!--                    <td>{{ getRes("Document.Ref") }}:</td>-->
    <!--                    <td class="q-pl-sm">{{ todo.ref }}</td>-->
    <!--                  </tr>-->
    <!--                  <tr class="q-tr--no-hover">-->
    <!--                    <td>{{ getRes("Document.Subject") }}:</td>-->
    <!--                    <td style="padding-left: 10px">-->
    <!--                      {{ todo.subj }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  <tr class="q-tr--no-hover">-->
    <!--                    <td>{{ getRes("CustMenu.Col.DateCreated") }}:</td>-->
    <!--                    <td class="q-pl-sm">-->
    <!--                      {{ $proSmart.common.getFormattedDate(todo.dateCreated) }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  <tr class="q-tr--no-hover" v-if="todo.procurerId">-->
    <!--                    <td>{{ getRes("procurerId") }}:</td>-->
    <!--                    <td class="q-pl-sm">-->
    <!--                      {{ todo.procurerId }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  <tr class="q-tr--no-hover" v-if="todo.tendererId">-->
    <!--                    <td>{{ getRes("tendererId") }}:</td>-->
    <!--                    <td class="q-pl-sm">-->
    <!--                      {{ todo.tendererId }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  <tr class="q-tr--no-hover" v-if="todo.supplierId">-->
    <!--                    <td>{{ getRes("supplierId") }}:</td>-->
    <!--                    <td class="q-pl-sm">-->
    <!--                      {{ todo.supplierId }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                </tbody>-->
    <!--              </q-markup-table>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </q-item-section>-->

    <!--        <q-item-section v-if="todo.targetDate" side>-->
    <!--          <q-item-label class="prosmart-text-important"-->
    <!--            >{{ getDateDiffStr(todo.targetDate) }}-->
    <!--          </q-item-label>-->
    <!--          <q-item-label class="text-h6 prosmart-text-important"-->
    <!--            >{{ $proSmart.common.format.dateTime(new Date(todo.targetDate)) }}-->
    <!--          </q-item-label>-->
    <!--        </q-item-section>-->
    <!--      </template>-->
    <!--      <template>-->
    <!--        <div class="row">-->
    <!--          <div class="col-auto">-->
    <!--            <q-markup-table dense flat separator="none">-->
    <!--              <thead></thead>-->
    <!--              <tbody>-->
    <!--                <tr class="q-tr--no-hover">-->
    <!--                  <td>{{ getRes("Supplier.SupplierId") }}:</td>-->
    <!--                  <td>{{ todo.ref }}</td>-->
    <!--                </tr>-->
    <!--                <tr class="q-tr--no-hover">-->
    <!--                  <td>{{ getRes("Supplier.EmailAddress") }}:</td>-->
    <!--                  <td>{{ todo.subject }}</td>-->
    <!--                </tr>-->
    <!--                <tr class="q-tr--no-hover" v-if="todo.procurerId">-->
    <!--                  <td>{{ getRes("Supplier.CCPerson") }}:</td>-->
    <!--                  <td>{{ todo.procurerId }}</td>-->
    <!--                </tr>-->
    <!--                <tr class="q-tr--no-hover" v-if="todo.tendererId">-->
    <!--                  <td>{{ getRes("Supplier.CCPerson") }}:</td>-->
    <!--                  <td>{{ todo.tendererId }}</td>-->
    <!--                </tr>-->
    <!--                <tr class="q-tr--no-hover" v-if="todo.supplierId">-->
    <!--                  <td>{{ getRes("Supplier.CCPerson") }}:</td>-->
    <!--                  <td>{{ todo.supplierId }}</td>-->
    <!--                </tr>-->
    <!--                <tr class="q-tr--no-hover">-->
    <!--                  <td>{{ getRes("Form.Col.SecurityCode") }}:</td>-->
    <!--                  <td>{{ todo.details }}</td>-->
    <!--                </tr>-->
    <!--              </tbody>-->
    <!--            </q-markup-table>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </pro-expansion-item>-->
  </div>
</template>

<script>
// import ProExpansionItem from "@/components/PROSmart/QuasarWrapperComponent/ProExpansionItem";
import ProReport from "@/components/PROSmart/Report/ProReport";

export default {
  name: "TodoListCard",
  components: { ProReport },
  data: function () {
    return {
      columns: [
        {
          name: "ref",
          i18NLabel: "Form.Field.DocRef",
          field: "ref",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "subj",
          i18NLabel: "Form.Field.Subject",
          field: "subj",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "details",
          i18NLabel: "Form.Field.details",
          field: "details",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "dateCreated",
          i18NLabel: "CustMenu.Col.DateCreated",
          field: "dateCreated",
          align: "left",
          sortable: true,
          style: "width: 200px",
          searchable: true,
          type: "datetime",
          convertTo: "datetime",
        },
        {
          name: "targetDate",
          i18NLabel: "Form.Field.TargetCompletionDate",
          field: "targetDate",
          align: "left",
          sortable: true,
          style: "width: 200px",
          searchable: true,
          type: "datetimeNull",
          convertTo: "datetimeNull",
        },
      ],
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    clickable: {
      type: Boolean,
    },
  },
  computed: {
    todoItems() {
      return this.data.map((todo) => ({
        ...todo,
        class: this.getClass(todo),
        onClick: this.clickable
          ? () => {
              this.saveTodoItem(todo);
              this.$router.push(this.getRoute(todo));
            }
          : null,
      }));
    },
  },
  methods: {
    getClass(todo) {
      let now = new Date();
      if (todo.targetDate && now - todo.targetDate > 0) return "text-required";

      let diff = Math.abs(now - todo.dateCreated);
      let minutes = Math.floor(diff / 1000 / 60);

      if (minutes <= 30) return "text-completed";

      return "";
    },
    getDateDiffStr(date) {
      const days = Math.floor((new Date() - date) / (24 * 3600 * 1000));

      if (days > 0) {
        return `Due ${days} days ago`;
      } else if (days === 0) {
        return `Due today`;
      } else {
        return `Due in ${-days} days`;
      }
    },
    getRoute(todo) {
      if (!this.clickable) return {};
      let params = {
        id: todo.tenderId,
        userRole: todo.userRole,
        hcmUserGroup: todo.hcmUserGroup,
        category: todo.category,
        requestType: todo.requestType,
      };

      for (let key in todo.additionalInfo) {
        params[key] = todo.additionalInfo[key];
      }

      return {
        name: todo.routeName || "DocumentDashboard",
        params,
      };
    },
    saveTodoItem({
      tenderId,
      category,
      requestType,
      targetWorkflowCode,
      allowMultiple,
    }) {
      sessionStorage.setItem(
        "todoItem",
        JSON.stringify({
          tenderId,
          category,
          requestType,
          targetWorkflowCode,
          allowMultiple,
        })
      );
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/styles/quasar.variables";

.q-list /deep/ {
  .q-item.q-router-link--active {
    color: initial;
  }
}

.prosmart-new {
  border-left: $completed solid 6px;

  .prosmart-text-important {
    color: $completed;
  }
}

.prosmart-overdue {
  border-left: $required solid 6px;
  cursor: default !important;

  .prosmart-text-important {
    color: $required;
  }
}

.prosmart-unread {
  border-left: $unread solid 6px;

  .prosmart-text-important {
    color: $unread;
  }
}
</style> -->
