<template>
  <el-container>
    <el-main style="overflow: auto !important">
      <pro-document-breadcrumbs
        :breadcrumbs="breadcrumbs"
        user-role="procurer"
      ></pro-document-breadcrumbs>

      <div class="q-pa-md">
        <div class="row items-center q-pb-sm">
          <div class="col q-ml-sm">
            <q-tabs
              v-model="tab"
              dense
              class="text-grey"
              active-color="primary"
              indicator-color="primary"
              align="left"
              narrow-indicator
              style="width: 800px"
            >
              <q-tab name="toDoList" class="text-h4">
                <div class="pro-smart-tab">
                  {{ getRes("CustMenu.Col.TodoList") }}
                  <q-badge color="primary" align="middle"
                    >{{ todoListFiltered[0].length }}
                  </q-badge>
                </div>
              </q-tab>
              <q-tab
                name="expireSoon"
                class="text-h4"
                v-if="todoListFiltered[1].length > 0"
              >
                <div class="pro-smart-tab">
                  {{ getRes("CustMenu.Col.ExpireIn1Day") }}
                  <q-badge color="error" align="middle"
                    >{{ todoListFiltered[1].length }}
                  </q-badge>
                </div>
              </q-tab>
              <q-tab name="toDoListExpired" class="text-h4">
                <div class="pro-smart-tab">
                  {{ getRes("CustMenu.Col.TodoListExpired") }}
                  <q-badge color="error" align="middle"
                    >{{ toDoListExpired.length }}
                  </q-badge>
                </div>
              </q-tab>
            </q-tabs>
          </div>
          <!-- <div class="col-4">
            <div>
              <q-input outlined dense v-model="keyword">
                <template v-slot:append>
                  <q-icon name="PROSmart-Search" />
                </template>
              </q-input>
            </div>
          </div> -->
        </div>

        <q-tab-panels v-model="tab">
          <q-tab-panel
            name="expireSoon"
            style="padding: 0"
            v-if="todoList.length > 0"
          >
            <q-card class="overflow-hidden" style="padding: 0">
              <q-list separator>
                <todo-list-card :data="todoListFiltered[1]" :clickable="true" />
              </q-list>
            </q-card>
          </q-tab-panel>

          <q-tab-panel
            name="toDoList"
            style="padding: 0"
            v-if="todoList.length > 0"
          >
            <q-card class="overflow-hidden" style="padding: 0">
              <q-list separator>
                <todo-list-card :data="todoListFiltered[0]" :clickable="true" />
              </q-list>
            </q-card>
          </q-tab-panel>

          <q-tab-panel
            name="toDoListExpired"
            style="padding: 0"
            v-if="toDoListExpired.length > 0"
          >
            <q-card class="overflow-hidden" style="padding: 0">
              <q-list separator>
                <todo-list-card
                  :data="todoListFilteredExpired"
                  :clickable="false"
                />
                <!-- <todo-list-card
                  :data="todoListFilteredExpired[0]"
                  :clickable="false"
                /> -->
              </q-list>
            </q-card>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import TodoListCard from "@/components/PROSmart/TodoList/TodoListCard";

export default {
  components: { TodoListCard, ProDocumentBreadcrumbs },
  data() {
    return {
      breadcrumbs: [
        {
          name: "menu.TodoList",
          icon: "PROSmart-Todo",
        },
      ],
      todoList: [],
      keyword: "",
      tab: "toDoList",
      toDoListExpired: [],
      init: true,
    };
  },
  computed: {
    todoListFiltered() {
      return this.getTodoListFiltered(false);
    },
    todoListFilteredExpired() {
      const list = this.getTodoListFiltered(true);
      return list[1].concat(list[0]);
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   // if()…
    //   console.log(this.todoListFiltered);
    // });
    this.$proSmart.procurerUi
      .getToDoListItemsByUserId(this)
      .then(async (list) => {
        let fullList = list.map((item, index) => {
          return {
            id: index,
            tenderId: item.tenderId,
            ref: item.ref,
            subj: item.subject,
            details: this.getRes(item.details),
            targetDate:
              item.targetDate == null ? null : new Date(item.targetDate),
            userRole: item.userRole,
            hcmUserGroup: item.hcmUserGroup,
            routeName: item.routeName,
            category: item.category,
            requestType: item.requestType,
            deadlineDate: item.deadlineDate,
            procurerId: item.procurerId,
            tendererId: item.tendererId,
            supplierId: item.supplierId,
            dateCreated: new Date(item.dateCreated),
            additionalInfo:
              item.additionalInfo !== undefined
                ? JSON.parse(item.additionalInfo)
                : {},
            targetWorkflowCode: item.targetWorkflowCode,
            allowMultiple: item.allowMultiple,
          };
        });

        let now = new Date();

        this.todoList = fullList
          .filter((b) => b.targetDate === null || b.targetDate > now)
          .sort(function (a, b) {
            return b.dateCreated - a.dateCreated;
          });

        this.toDoListExpired = fullList
          .filter((b) => b.targetDate !== null && b.targetDate <= now)
          .sort(function (a, b) {
            return b.dateCreated - a.dateCreated;
          });
      });
  },
  methods: {
    getTodoListFiltered(expired) {
      let dataList = expired ? this.toDoListExpired : this.todoList;
      let dueDateList = [[], []];

      dataList.forEach((b) => {
        dueDateList[this.getDateDiffStr(b.targetDate)].push(b);
      });

      if (!this.keyword) {
        return dueDateList;
      } else {
        const keywordLowerCase = this.keyword.toLowerCase();
        return [
          dueDateList[0].filter(
            (todo) =>
              todo.details.toLowerCase().includes(keywordLowerCase) ||
              todo.ref.toLowerCase().includes(keywordLowerCase) ||
              todo.subj.toLowerCase().includes(keywordLowerCase)
          ),
          dueDateList[1].filter(
            (todo) =>
              todo.details.toLowerCase().includes(keywordLowerCase) ||
              todo.ref.toLowerCase().includes(keywordLowerCase) ||
              todo.subj.toLowerCase().includes(keywordLowerCase)
          ),
        ];
      }
    },
    getDateDiffStr(date) {
      if (date === 0) return 0;
      const days = Math.floor((new Date() - date) / (24 * 3600 * 1000));

      if (days > 0) {
        //Due ${days} days ago
        return 0;
      } else if (days >= -1) {
        //Due today
        return 1;
      } else {
        //Due in ${-days} days
        return 0;
      }
    },
  },
  watch: {
    todoListFiltered(newValue) {
      if (this.init && newValue[1].length > 0) {
        this.tab = "expireSoon";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pro-smart-tab {
  padding-left: 10px;
  padding-right: 10px;
  text-transform: capitalize;
}
</style>
