import { render, staticRenderFns } from "./ProDocumentBreadcrumbs.vue?vue&type=template&id=6033356a&scoped=true&"
import script from "./ProDocumentBreadcrumbs.vue?vue&type=script&lang=js&"
export * from "./ProDocumentBreadcrumbs.vue?vue&type=script&lang=js&"
import style0 from "./ProDocumentBreadcrumbs.vue?vue&type=style&index=0&id=6033356a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6033356a",
  null
  
)

export default component.exports
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QToolbar,QBreadcrumbs,QBreadcrumbsEl,QSpace,QBtn,QSeparator,QBtnDropdown,QList,QItem,QItemSection,QItemLabel});qInstall(component, 'directives', {ClosePopup});
